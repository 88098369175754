<template>
  <v-data-table
    :headers="headers"
    :items="formattedItems"
    :search="search"
    :loading="loading"
    :items-per-page="15"
    :disable-pagination="formattedItems.length <= 15"
    :hide-default-footer="formattedItems.length <= 15"
    :class="{ 'content-loading': loading }"
    class="issue-creator-statistics-table"
    item-key="assigned_to_user_name"
    dense
  />
</template>

<script>
import { format } from 'date-fns';
import { mapGetters } from 'vuex';

export default {
  name: 'IssueCreatedByStatisticsTable',

  props: {
    search: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('settings', ['defaultDateFnsConfig']),

    headers() {
      const headers = [];
      headers.push({
        text: this.$t('general.user'),
        value: 'assigned_to_user_name',
        width: 200,
      });
      for (let i = 0; i < 12; i++) {
        headers.push({
          text: format(new Date().setMonth(i), 'LLLL', this.defaultDateFnsConfig),
          value: (i + 1).toString(),
          align: 'end',
          class: 'text-no-wrap',
        });
      }
      return headers;
    },

    formattedItems() {
      const dataSet = {};
      this.items.forEach((item) => {
        if (!dataSet[item.assigned_to]) {
          dataSet[item.assigned_to] = {
            assigned_to_user_name:
              item.assigned_to_user_name || this.$t('projects.labels.unassigned'),
          };
          for (let i = 1; i <= 12; i++) {
            dataSet[item.assigned_to][i] = '';
          }
        }
        dataSet[item.assigned_to][item.month] = item.issues_count || item.issue_counts; // backend inconsistencies :-(
      });
      return Object.values(dataSet);
    },
  },
};
</script>

<style scoped></style>
