<template>
  <div>
    <IssueCreatedByStatisticsFilter
      :filter-params="issueCreatedByStatisticsFilterParams"
      :loading="loading[`get:api/project-issues-statistic`]"
      @change="$router.push({ name: 'issueCreatedByStatistics', query: $event })"
    />

    <IssueCreatedByStatisticsTable
      v-if="!isCurrentDatasetGroupedByProject"
      :loading="loading[`get:api/project-issues-statistic`]"
      :items="issueCreatedByStatistics"
      :search="issueCreatedByStatisticsFilterParams.search"
    />

    <template v-else>
      <div v-for="project in issueCreatedByStatistics" :key="project.project_id">
        <h2 class="text-h6 px-3 pt-3">
          {{ project.project_name }}
        </h2>

        <IssueCreatedByStatisticsTable
          :loading="loading[`get:api/project-issues-statistic`]"
          :items="project.issue_counts"
          :search="issueCreatedByStatisticsFilterParams.search"
        />
      </div>
    </template>
  </div>
</template>

<script>
import IssueCreatedByStatisticsFilter from '@/components/filters/IssueCreatedByStatisticsFilter';
import { mapActions, mapGetters, mapState } from 'vuex';
import { getSanitizedFilterParams } from '@/util/filter-params';
import IssueCreatedByStatisticsTable from '@/components/tables/IssueCreatedByStatisticsTable';

export default {
  name: 'IssueCreatedByStatistics',

  components: { IssueCreatedByStatisticsTable, IssueCreatedByStatisticsFilter },

  computed: {
    ...mapState('projectIssueCreatedByStatistics', [
      'issueCreatedByStatistics',
      'issueCreatedByStatisticsFilterParams',
    ]),
    ...mapGetters(['loading']),
  },

  data() {
    return {
      isCurrentDatasetGroupedByProject: false,
    };
  },

  async created() {
    await this.fetchIssueCreatedByStatistics(getSanitizedFilterParams(this.$route.query));
    this.isCurrentDatasetGroupedByProject =
      this.issueCreatedByStatisticsFilterParams.group_by_project;
  },

  beforeRouteUpdate(to, from, next) {
    const params = getSanitizedFilterParams(to.query);
    if (
      JSON.stringify(this.issueCreatedByStatisticsFilterParams) !== JSON.stringify(params) &&
      to.name === 'issueCreatedByStatistics'
    ) {
      this.fetchIssueCreatedByStatistics(to.query).then(() => {
        this.isCurrentDatasetGroupedByProject =
          this.issueCreatedByStatisticsFilterParams.group_by_project;
      });
    }
    next();
  },

  methods: {
    ...mapActions('projectIssueCreatedByStatistics', ['fetchIssueCreatedByStatistics']),
  },
};
</script>

<style scoped></style>
