<template>
  <v-row class="d-flex align-center pa-4">
    <v-col cols="6" md="4">
      <v-checkbox
        v-model="params.group_by_project"
        :label="$t('projects.general.group_by_project')"
        :hide-details="$vuetify.breakpoint.mdAndUp"
        class="pt-0 mt-0"
        @change="submitFilters"
      />
    </v-col>
    <v-col cols="6" md="4" class="d-flex justify-end justify-md-center align-center">
      <v-btn :disabled="loading" class="mr-2" icon @click="onYearChange(+params.year - 1)">
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <span class="text-h6">
        {{ params.year }}
      </span>
      <v-btn :disabled="loading" class="ml-2" icon @click="onYearChange(+params.year + 1)">
        <v-icon>chevron_right</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="12" md="4" class="d-flex justify-end">
      <v-text-field
        v-model="params.search"
        :label="$t('general.search_for_user')"
        :style="{ 'max-width': $vuetify.breakpoint.smAndDown ? '100%' : '250px' }"
        :dense="$vuetify.breakpoint.mdAndUp"
        class="mt-0"
        prepend-icon="search"
        clearable
        hide-details
        single-line
        @input="handleDebounce"
      />
    </v-col>
  </v-row>
</template>

<script>
import filterMixin from '@/mixins/filter-mixin';

export default {
  name: 'IssueCreatedByStatisticsFilter',

  mixins: [filterMixin],

  props: {
    filterParams: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      params: {},
    };
  },

  methods: {
    onYearChange(value) {
      this.params.year = value;
      this.submitFilters();
    },
  },
};
</script>

<style scoped></style>
